import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSubscribe } from "hooks/Forms/useSubscribe";
import { useLocation } from "@reach/router";
import StayInformedThankYou from "./TranslatorHubDetails/StayInformedThankYou";
import formCommonPostData from "utils/form-common-post-data";
import { errorMessageEmail, patternEmail } from "utils/form-validation";

const FormMobile = ({ marginX }) => {
  const [showForm, setShowForm] = useState(true);
  const [formStatus, setFormStatus] = useState(false);
  const { mutateAsync } = useSubscribe();
  const location = useLocation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const postData1 = {
      email: data.email,
      pagelink: location.href,
    };
    const postData = { ...postData1, ...formCommonPostData() };
    mutateAsync(postData).then((_) => {
      reset();
      setFormStatus(true);
      setShowForm(false);
    });
  };

  return (
    <React.Fragment>
      {showForm && (
        <div
          className={`sm:hidden w-72 mx-auto border border-newBorder py-10 my-auto mt-10 bg-staryinformedBgMobile bg-cover bg-center lg:bg-none sm:bg-lightestBlue text-center ${
            marginX ? marginX : ""
          }  `}
        >
          <div className="text-2xl font-bold text-center text-white">
            <span>STAY </span>INFORMED
          </div>
          <p className="text-sm text-center py-6 text-white">
            Subscribe to receive all the latest updates from Tomedes.
          </p>{" "}
          <form onSubmit={handleSubmit(onSubmit)}>
            {errors.email && (
              <span className="text-red text-sm ">
                {errorMessageEmail}
              </span>
            )}
            <input
              type="email"
              name="email"
              autoComplete="off"
              {...register("email", {
                required: true,
                pattern: {
                  value: patternEmail,
                },
              })}
              placeholder="Enter your business email"
              className="bg-white m-auto block text-sm mb-6 pl-2 w-11/12 lg:w-9/12 sm:w-10/12 border border-newBorder py-2 xs:font-opensans"
            />
            <input type="hidden" id="zc_gad" name="zc_gad" value="" />
            <button
              type="submit"
              className="bg-darkestBlue hover:bg-hoverBtn py-3 text-borderColor font-bold px-4 text-sm block m-auto uppercase"
            >
              Keep Me Updated
            </button>
          </form>
        </div>
      )}
      {formStatus && (
        <div className="sm:hidden">
          <StayInformedThankYou
            setShowForm={setShowForm}
            setFormStatus={setFormStatus}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default FormMobile;
