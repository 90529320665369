import { useInfiniteQuery } from "react-query";
import { endpoints } from "utils/endpoints";
import http from "utils/http";

const fetchComments = ({ pageParam = 1, queryKey }) => {
  const id = queryKey[1];
  const newEndpoints = endpoints.comments.getComments.replace(
    "?seoid={id}&page={pagenumber}",
    "?seoid=" + id + "&page=" + pageParam
  );
  return http().get(newEndpoints);
};

export function useFetchComments(id) {
  return useInfiniteQuery(["fetchComments", id], fetchComments, {
    getNextPageParam: (lastPage) => {
      const typeCasteValue = parseInt(lastPage.current_page);
      if (typeCasteValue < lastPage.total_pages) return typeCasteValue + 1;
      return false;
    },
  });
}
