import React from "react";

export default function StayInformedThankYou({ setShowForm, setFormStatus }) {
  const handleThankyou = () => {
    setShowForm(true);
    setFormStatus(false);
  };

  return (
    <div id="stayinformedThankyou" class="sm:hidden w-72 mx-auto thankyou classImp2 bg-staryinformedBgMobile bg-cover bg-center py-16 sm:py-24 px-7 sm:px-11  text-white mt-10 mb-8 ">
      <h2 class="font-secondary text-[32px] sm:text-46 font-bold mb-5 text-center sm:text-left">Thank you!</h2>
      <p class="font-opensans font-semibold text-17 sm:text-[20px] text-center sm:text-left my-5">Please check your inbox for a confirmation email.</p>
      <button class="bg-[#001546] hover:bg-[#0032A7] font-opensans font-semibold text-14 text-[#E6E6E6] py-2 px-8 transition-colors duration-300 sm:hidden w-full" onClick={handleThankyou}>
        Close
      </button>
    </div>
  );
}
