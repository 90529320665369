import React, { useRef, useState } from "react";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import Loader from "components/loader";
import { useFetchComments } from "hooks/Comments/useFetchComments";
import { usePostComments } from "hooks/Comments/usePostComments";
import { useForm } from "react-hook-form";
import OptimizedImage from "hooks/OptimizedImage";
import { Link } from "gatsby";
import formCommonPostData from "utils/form-common-post-data";
import {
  errorMessage,
  errorMessageEmail,
  patternEmail,
} from "utils/form-validation";

function HubComments({ data, project_id }) {
  const [value, setValue] = useState(false);
  const [token, setToken] = useState(false);
  const [formStatus, setForStatus] = useState(false);
  const captchaRef = useRef(null);
  const [captchaVisible, setCaptchaVisible] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const {
    data: dataOne,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useFetchComments(project_id);

  const { status, mutateAsync } = usePostComments();

  const onSubmit = (data) => {
    if (token) {
      const postData1 = {
        name: data.name,
        email: data.email,
        content: data.content,
        seo_project_id: project_id,
        notification: value,
      };
      const postData = { ...postData, ...formCommonPostData() };
      mutateAsync(postData).then((_) => {
        reset();
        setForStatus(true);
      });
    } else {
      alert("Please verify captcha");
    }
  };

  const handleChange = (e) => {
    const { checked } = e.target;
    setValue(checked);
  };

  const handleVerify = () => {
    setToken(true);
  };

  return (
    <>
      <div className="commentBar pt-4  border-t-2 border-blackNew-800 mt-4">
        <h4 className="font-semibold text-xl">
          <span className="text-orange">Post</span> your Comment
        </h4>
        {dataOne?.pages.map((page) =>
          page.data.map((c, i) => (
            <div className="comments pt-6" key={i}>
              <div className="commentBox max-w-max bg-[#f2f3f5] rounded-lg p-3">
                <h2 className="font-medium text-lg capitalize">
                  <span>{c?.name}</span>
                </h2>
                <p>
                  <span>{c?.content}</span>
                </p>
                <span className="text-ligtBlue" style={{ color: "#3a7aff" }}>
                  {c?.created_date}
                </span>
              </div>
            </div>
          ))
        )}
        {hasNextPage && (
          <div className="loadMoreBtn pt-6">
            <button
              onClick={fetchNextPage}
              className="border rounded-lg px-3 py-2 text-white text-sm bg-[#5bc0de] cursor-pointer"
            >
              {isFetching ? <Loader /> : "Load more"}
            </button>
          </div>
        )}
      </div>
      <div className="formInfo py-6">
        {formStatus ? (
          <span className="text-[#5C90F7]">
            <i>
              Thank you! Your comment will be published as soon as possible.
            </i>
          </span>
        ) : (
          <></>
        )}
        <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
          <input
            type="text"
            className="border border-gray rounded-md p-1 pl-4 "
            placeholder="Name"
            name="name"
            autoComplete="off"
            {...register("name", { required: true })}
            onClick={() => setCaptchaVisible(true)}
          />
          {errors.name && (
            <span className="text-red text-xs sm:text-sm">{errorMessage}</span>
          )}
          <input
            type="email"
            className="border border-gray rounded-md p-1 pl-4 my-4"
            placeholder="Email (never display)"
            name="email"
            autoComplete="off"
            {...register("email", {
              required: true,
              pattern: {
                value: patternEmail,
              },
            })}
          />
          {errors.email && (
            <span className="text-red text-xs sm:text-sm pb-4 -mt-4">
              {errorMessageEmail}
            </span>
          )}
          <textarea
            cols="30"
            rows="10"
            className="border border-gray pl-4"
            placeholder="Comment"
            name="content"
            {...register("content", { required: true })}
          ></textarea>
          {errors.content && (
            <span className="text-red text-xs sm:text-sm">{errorMessage}</span>
          )}
          <div className="notification flex my-4 items-center gap-x-4">
            <input
              type="checkbox"
              onChange={(e) => handleChange(e)}
              defaultChecked={value}
            />
            <p>
              I want to receive a notification of new postings under this topic
            </p>
          </div>
          {captchaVisible && (
            <HCaptcha
              sitekey="b6b06718-08ae-457f-a6d7-147046b4c216"
              onVerify={handleVerify}
              ref={captchaRef}
            />
          )}
          <input type="hidden" id="zc_gad" name="zc_gad" value="" />
          <button
            type="submit"
            className="border rounded cursor-pointer border-black bg-black p-2.5 px-6 mt-10 text-white text-sm w-24"
          >
            {status === "loading" ? <Loader /> : "SUBMIT"}
          </button>
        </form>
      </div>
      <div className="flex items-center justify-items-center flex-col border-t-2 border-blackNew-800 mt-6 py-4">
        <span className="text-left w-full text-[22px] font-bold">
          <i className="text-orange">YOU MAY </i>
          <i className="text-blackNew-700">ALSO LIKE</i>
        </span>
        <div className="flex flex-col sm:flex-row sm:w-10/12 mr-auto gap-x-8">
          {data?.you_may_also_like.map((node, index) => (
            <div className="mt-5" key={index}>
              <Link to={node.slug}>
                <OptimizedImage
                  src={node.cover_image}
                  alt={node.alt}
                  className="min-w-[315px] min-h-[196px]  object-cover mb-6"
                />
              </Link>
              <Link to={node.slug}>
                <i className="text-blackNew-900 w-full">{node.title}</i>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
export default HubComments;
