import { Link } from "gatsby";
import React from "react";
import Hero from "assets/quoteimg.svg";

const RegisterQuote = () => {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${Hero})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="rounded-t-lg"
      >
        <div className="md:flex justify-around items-center gap-16 py-12 md:px-12 px-4">
          <div className="flex-col items-center justify-center ">
            <p className="font-primary leading-[44px] text-4xl font-semibold text-center text-[#ffffff]">
              Do It Yourself
            </p>
            <p className="py-4 font-opensans md:max-w-[320px] mx-auto  leading-6 text-lg font-normal text-center text-[#E1EFFE]">
              I want a free quote now and I'm ready to order my translations.
            </p>
            <div className="flex justify-center items-center">
              <Link to="/quote">
                <button className="bg-[#FF7B16] mt-6 text-[#FFFFFF] leading-6 px-6 py-2 rounded-lg">
                  Get Started
                </button>
              </Link>
            </div>
          </div>
          <div className="flex-col items-center justify-center mt-12 md:mt-0">
            <p className="font-primary leading-[44px] text-4xl font-semibold text-center text-[#ffffff]">
              Do It For Me
            </p>
            <p className="font-opensans py-4  md:max-w-[370px] mx-auto  leading-6 text-lg font-normal text-center text-[#E1EFFE]">
              I'd like Tomedes to provide a customized quote based on my
              specific needs.
            </p>
            <div className="flex justify-center items-center">
              <Link to="/contactus.php">
                <button className=" mt-6 text-[#FFFFFF] leading-6 px-6 py-2 rounded-lg border border-white">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#00173A] flex gap-2 items-center justify-center py-4 rounded-b-lg px-2">
        <p className="text-[#FFFFFF] font-opensans font-normal text-center text-sm md:text-lg ">
          Want to be part of our team?
        </p>
        <Link to="/user/provider/createaccount">
          <button className="text-[#2A7CF8] text-sm md:text-lg font-opensans text-semibold ">
            Register Here
          </button>
        </Link>
      </div>
    </>
  );
};
export default RegisterQuote;
